import { Component } from 'react'
import '../HomePage/HomePage.css'
import tbclogo from '../../assets/tbc_logo.svg'
import Carousel from 'react-bootstrap/Carousel'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class CancelPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      passwordVerified: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
  }

  componentDidMount () {
  }

  componentWillUnmount () {
  }

  handlePassword(event) {
    event.preventDefault();
    let password = document.getElementById("password").value
    var j = {
      password: password
    }
    fetch('https://tbc-api.click:5477/validate', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(j)
    }).then((res) => {
      console.log(res)
      if(res.status == 200){
        this.setState({
          passwordVerified: true
        })
      }else{
        alert("Invalid User creditials")
      }
    })
  }

  handleSubmit(event) {
   event.preventDefault()
   let aUser = "TBC Internal"

   var j = {
     authorizedUsername: aUser,
     password: ''
   }
   let cancelLink = 'https://tbc-api.click:5477/cancel/'+this.props.match.params.id+"/"+this.props.match.params.auth
   fetch(cancelLink, {
     method: 'PUT',
     headers: {'Content-Type': 'application/json'},
     body: JSON.stringify(j)
   }).then((res) => {
    if(res.status == 200) {
     alert("Your ride request has been successfully cancelled.")
   }else{
     alert("Invalid user creditials")
   }
 })
}

  render () {
    if(this.state.passwordVerified) {
      return (
              <div id="generic-form" className="generic-form generic-form-password">
                <img className="tbc-logo" src={tbclogo} height="200px"></img>
                <h4 style={{ margin: '2%', textAlign: "center"}}>Are you sure you want to cancel the Ride?</h4>
                <button className="submit-btn" style={{marginTop: "20px"}} onClick={this.handleSubmit}>
                  Cancel Request
                </button>
              </div>
      )
    } else {
        return (
          <div id="generic-form" className="generic-form generic-form-password">
            <img className="tbc-logo" src={tbclogo} height="200px"></img>
            <p style={{ margin: '2%', textAlign: "center", fontSize: "20px" }}>Enter Password</p>
            <Form.Group as={Col}>
              <Form.Control id="password" style={{ textAlign: 'center' }} type="password" required />
            </Form.Group>
            <Form.Group as={Col}>
              <button className="submit-btn" onClick={this.handlePassword}>
                <span id="button-text">Access</span>
              </button>
            </Form.Group>
            <div>
            </div>
          </div>
        )
      }
   }
}
export default CancelPage
