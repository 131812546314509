import { Component } from 'react'
import imgCheck from '../../assets/check.png'
import "./SuccessPage.css"

class SuccessPage extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    document.body.className = 'success-body'
    return (
      <div className="center-container">
        <div className="center-activation">
          <div id="alert-div" className="alert-success" role="alert">
            <div className="success-icon">
              <img height="40px" width="40px" src={imgCheck} />
            </div>
            <h4 id="activation-message">Your request has been submitted.</h4>
          </div>
        </div>
      </div>
    )
  }
}
export default SuccessPage
