import { Component } from 'react'
import '../HomePage/HomePage.css'
import conventionlogo18 from '../../assets/convention18.png'
import conventionlogo19 from '../../assets/convention19.jpg'
import conventionlogo20 from '../../assets/convention20.png'
import conventionlogo21 from '../../assets/convention21.png'
import conventionlogo22 from '../../assets/convention22.jpg'
import conventionlogo23 from '../../assets/convention23.png'
import conventionlogo24 from '../../assets/convention24.png'
import conventionlogo25 from '../../assets/convention25.png'
import conventionlogo26 from '../../assets/convention26.png'
import conventionlogo27 from '../../assets/convention27.png'
import Carousel from 'react-bootstrap/Carousel'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class CurrentConvention extends Component {
  constructor(props) {
    super(props)
    this.state = {
      today: new Date()
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  renderLogos() {
    if (this.state.today <= new Date('10/1/2021')) {
      return (
        <>
          <img width='30%' src={conventionlogo18} style={{ float: 'center', marginLeft: '20%', marginRight: '20%', marginTop: '10%', display: 'inline-block', backgroundColor: 'white' }} />
          <img width='40%' src={conventionlogo19} style={{ float: 'center', marginLeft: '10%', marginRight: '5%', marginTop: '5%', display: 'inline-block', backgroundColor: 'white' }} />
          <img width='30%' src={conventionlogo20} style={{ float: 'center', marginLeft: '5%', marginRight: '10%', marginTop: '5%', display: 'inline-block', backgroundColor: 'white' }} />
        </>
      )
    } else if (this.state.today <= new Date('10/4/2021')) {
      return (
        <>
          <img width='70%' src={conventionlogo21} style={{ float: 'center', marginLeft: '15%', marginRight: '15%', marginTop: '20%', display: 'inline-block', backgroundColor: 'white' }} />
        </>
      )
    } else if (this.state.today <= new Date('10/12/2021')) {
      return (
        <>
          <img width='70%' src={conventionlogo21} style={{ float: 'center', marginLeft: '15%', marginRight: '15%', marginTop: '10%', display: 'inline-block', backgroundColor: 'white' }} />
          <img width='70%' src={conventionlogo22} style={{ float: 'center', marginLeft: '15%', marginRight: '15%', marginTop: '5%', display: 'inline-block', backgroundColor: 'white' }} />

        </>
      )
    } else if (this.state.today <= new Date('10/14/2021')) {
      return (
        <>
          <img width='50%' src={conventionlogo21} style={{ float: 'center', marginLeft: '15%', marginRight: '15%', marginTop: '5%', display: 'inline-block', backgroundColor: 'white' }} />
          <img width='40%' src={conventionlogo22} style={{ float: 'center', marginLeft: '5%', marginRight: '5%', marginTop: '5%', display: 'inline-block', backgroundColor: 'white' }} />
          <img width='40%' src={conventionlogo23} style={{ float: 'center', marginLeft: '5%', marginRight: '5%', marginTop: '5%', display: 'inline-block', backgroundColor: 'white' }} />
        </>
      )
    } else   if (this.state.today <= new Date('10/17/2021')) {
        return (
          <>
            <img width='40%' src={conventionlogo22} style={{ float: 'center', marginLeft: '5%', marginRight: '5%', marginTop: '10%', display: 'inline-block', backgroundColor: 'white' }} />
            <img width='40%' src={conventionlogo23} style={{ float: 'center', marginLeft: '5%', marginRight: '5%', marginTop: '10%', display: 'inline-block', backgroundColor: 'white' }} />
          </>
        )
    } else if (this.state.today <= new Date('10/23/2021')) {
      return (
        <>
          <img width='40%' src={conventionlogo23} style={{ float: 'center', marginLeft: '20%', marginRight: '20%', marginTop: '5%', display: 'inline-block', backgroundColor: 'white' }} />
          <img width='40%' src={conventionlogo24} style={{ float: 'center', marginLeft: '5%', marginRight: '5%', marginTop: '5%', display: 'inline-block', backgroundColor: 'white' }} />
          <img width='40%' src={conventionlogo25} style={{ float: 'center', marginLeft: '5%', marginRight: '5%', marginTop: '5%', display: 'inline-block', backgroundColor: 'white' }} />
        </>
      )
    } else if (this.state.today <= new Date('10/25/2021')) {
        return (
          <>
            <img width='60%' src={conventionlogo25} style={{ float: 'center', marginLeft: '5%', marginRight: '5%', marginTop: '10%', display: 'inline-block', backgroundColor: 'white' }} />
            <img width='60%' src={conventionlogo26} style={{ float: 'center', marginLeft: '5%', marginRight: '5%', marginTop: '5%', display: 'inline-block', backgroundColor: 'white' }} />
          </>
        )
    } else if (this.state.today <= new Date('11/13/2021')) {
      return (
        <>
          <img width='60%' src={conventionlogo27} style={{ float: 'center', marginLeft: '5%', marginRight: '6%', marginTop: '10%', display: 'inline-block', backgroundColor: 'white'}} />
        </>
      )      
    } else {
      return (
        <>
          <img width='80%' src={conventionlogo26} style={{ float: 'center', marginLeft: '5%', marginRight: '5%', marginTop: '8%', display: 'inline-block', backgroundColor: 'white' }} />
        </>
      )
    }
  }

  render() {
    window.setTimeout(() => {
      window.location.reload();
    }, 7200000)
    return (
      <div style={{
        backgroundColor: '#ffffff',
        width: '100%',
        height: '200vh'
      }}>
        {this.renderLogos()}
      </div>
    )
  }
}
export default CurrentConvention
