import React from "react";
import "./Navbar.css"

const Navbar = () => {
    const pathname = window.location.pathname.slice(1);
    return (
        <div className="topnav">
            {pathname.includes("success") && <a className="hover-underline-animation-top" href="/" id="cache-header">
                Home
            </a>}
            <a className="hover-underline-animation-top" href="https://lvloop.com/tickets" id="cache-header">
                Tickets
            </a>
            <a className="hover-underline-animation-top" href="https://www.boringcompany.com/vegas-loop">
                About
            </a>
        </div>
    );
};

export default Navbar;
