import { Component, createRef } from 'react'
import moment from 'moment-timezone'
import './HomePage.css'
// import tbclogo from '../../assets/tbc_logo.png'
import tbclogo from '../../assets/tbc_logo.svg'
import Carousel from 'react-bootstrap/Carousel'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal'
import "./HomePage.css"

const departments = [
  'Building Sales',
  'CEO Office',
  'Customer Safety',
  'Destination Sales',
  'Engineering',
  'Facility Operations/Services',
  'Finance',
  'Guest Experience/Convention Services',
  'International Sales',
  'IT (Information Technology)',
  'Legal',
  'Leisure Sales',
  'Marketing',
  'People & Culture',
  'PR (Public Relations)',
  'Public Affairs',
  'Sports and Events',
  'Traffic',
  "Others",
  'TBC - Internal',
];

const CLOSED_THRESHOLD_MINUTES = 15
const OPEN_THRESHOLD_MINUTES = 10
class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      passwordVerified: false,
      show: false,
      password: '',
      checkedbox: true,
      hours: 12,
      mins: 0,
      zone: "PM",
      date: "",
      isValidTime: false,
      hoursOperation: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showShow = this.showShow.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
  }


  initStates = async () => {
    const operationResponse = await this.getOperationHours()
    const tempOperations = {}
    operationResponse.map((op) => {
      const currDate = moment(op.dateString, "MMMM DD, YYYY");
      const formattedDate = currDate.format("YYYY-MM-DD")
      const isClosed = op.hours === "Closed"
      tempOperations[formattedDate] = { date: currDate, isClosed: isClosed }
      if (!isClosed) {
        const [startTime, endTime] = op.hours.split("-");
        const startMoment = moment(startTime, 'hh:mma');
        const endMoment = moment(endTime, 'hh:mma');
        const startHourDecimal = startMoment.hours() + startMoment.minutes() / 60;
        const endHourDecimal = endMoment.hours() + endMoment.minutes() / 60;
        tempOperations[formattedDate].startHour = startHourDecimal
        tempOperations[formattedDate].endHour = endHourDecimal
      }
    })
    const tempValidity = this.checkTime(tempOperations);

    const currentDateString = moment.tz('America/Los_Angeles').format('YYYY-MM-DD');
    let currentThreshold = OPEN_THRESHOLD_MINUTES
    if (tempOperations[currentDateString] && tempOperations[currentDateString].isClosed) {
      currentThreshold = CLOSED_THRESHOLD_MINUTES
    }
    var now = new Date();
    var i = 5
    var thresholdFromNow = new Date(now.getTime() + currentThreshold * 60000)
    while (thresholdFromNow.getMinutes() % 10 != 0 && i < 15) {
      i = i + 1
      thresholdFromNow = new Date(now.getTime() + i * 60000)
    }
    var fivehour = (thresholdFromNow.getHours() % 12)
    var fivemins = thresholdFromNow.getMinutes() / 10 * 10
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    var today = now.getFullYear() + "-" + (month) + "-" + (day);
    var zone = ""
    if (thresholdFromNow.getHours() > 12) {
      zone = "PM"
    } else {
      zone = "AM".slice(-2);
    }
    this.setState({
      hours: fivehour,
      mins: fivemins,
      zone: zone,
      date: today,
      isValidTime: tempValidity,
      hoursOperation: tempOperations
    })

  }

  componentDidMount() {
    this.initStates()
    this.interval = setInterval(() => {
      const tempValidity = this.checkTime();
      if (this.state.isValidTime !== tempValidity) {
        this.setState(prevState => ({
          ...prevState,
          isValidTime: tempValidity
        }));
      }
    }, 1 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handlePassword(event) {
    event.preventDefault();
    let password = document.getElementById("password").value
    var j = {
      password: password
    }
    fetch('https://tbc-api.click:5477/validate', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(j)
    }).then((res) => {
      if (res.status == 200) {
        this.setState({
          passwordVerified: true,
          password: password
        })
      } else {
        alert("Invalid User creditials")
      }
    })
  }



  handleCheckbox(currState) {
    this.setState({
      checkedbox: currState
    })
  }

  cancelShow() {
    this.setState({ show: false, passwordVerified: false })
    this.forceUpdate()
  }

  showShow() {
    //this.setState({ show : true})
  }

  handleDate(e) {

    this.setState({
      date: e.target.value
    })
  }

  isValidPhoneNumber(phoneUser) {
    function isDigit(char) {
      return /\d/.test(char);
    }

    let allCharactersValid = true;
    let lengthValid = phoneUser.length >= 8 && phoneUser.length <= 15;

    if (!lengthValid) {
      return false; // Return false if length is not within the valid range
    }

    for (let i = 0; i < phoneUser.length; i++) {
      let currentChar = phoneUser.charAt(i);
      if (!isDigit(currentChar)) {
        allCharactersValid = false;
        break;
      }
    }
    return allCharactersValid;
  }

  handleSubmit(event) {
    event.preventDefault();
    let emailUser = document.getElementById("emailUser").value || '';
    let phoneUser = document.getElementById("phoneUser").value || '';
    let driverStandby = document.getElementById("standbyCheckbox").checked || false
    let deptUser = document.getElementById("deptUser").options[document.getElementById("deptUser").selectedIndex].value || '';
    let nPassenger = parseInt(document.getElementById("aPassenger").options[document.getElementById("aPassenger").selectedIndex].value)
    var tDate = document.getElementById("tDate").value
    var tHour = parseInt(document.getElementById("aHour").options[document.getElementById("aHour").selectedIndex].value)
    var tMin = parseInt(document.getElementById("aMin").options[document.getElementById("aMin").selectedIndex].value)
    var zone = document.getElementById("aZone").options[document.getElementById("aZone").selectedIndex].value;
    if ((zone == "PM" && tHour != 12) || (zone == "AM" && tHour == 12)) {
      tHour = (tHour + 12) % 24
    }
    const formattedTHour = tHour.toString().padStart(2, '0');
    const formattedTMin = tMin.toString().padStart(2, '0');
    let selectedTime = moment.tz(`${tDate} ${formattedTHour}:${formattedTMin}`, "America/Los_Angeles")
    var tTime = ("0" + tHour).slice(-2) + ":" + ("0" + tMin).slice(-2)
    var pickup = document.getElementById("tPickup").options[document.getElementById("tPickup").selectedIndex].value;
    var dropoff = document.getElementById("tDropoff").options[document.getElementById("tDropoff").selectedIndex].value;
    let notes = document.getElementById("tNotes").value
    var now = new Date();
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    var today = now.getFullYear() + "-" + (month) + "-" + (day);
    var hour = ("0" + now.getHours()).slice(-2);
    var mins = ("0" + now.getMinutes()).slice(-2);
    var curTime = hour + ":" + mins

    // validity checks
    if (!this.state.checkedbox && ((tDate < today) || ((tDate == today) && (tTime < curTime)))) {
      alert("Invalid Date. We are not providing time travel services just yet.")
      return
    }

    const currentDateString = moment.tz('America/Los_Angeles').format('YYYY-MM-DD');
    let currentThreshold = OPEN_THRESHOLD_MINUTES
    if (this.state && this.state.hoursOperation && this.state.hoursOperation[currentDateString] && this.state.hoursOperation[currentDateString].isClosed) {
      currentThreshold = CLOSED_THRESHOLD_MINUTES
    }

    var fifteenFromNow = new Date(now.getTime() + currentThreshold * 60 * 1000)
    var fifteenHour = ("0" + fifteenFromNow.getHours()).slice(-2);
    var fifteenMinutes = ("0" + fifteenFromNow.getMinutes()).slice(-2);
    var thresholdTime = fifteenHour + ":" + fifteenMinutes

    if (!this.state.checkedbox && (tDate == today)) {
      if (tTime < thresholdTime) {
        alert("Please select time at least " + currentThreshold + " minutes in the future")
        return
      }
    }


    if (this.state.checkedbox) {
      tTime = thresholdTime
      tDate = today
      selectedTime = moment.tz(`${tDate} ${tTime}`, "America/Los_Angeles")
    }

    if (!this.validateHours(this.state.hoursOperation, selectedTime)) {
      alert("Invalid date or time selected. Please note: rides may only be requested from Monday to Friday between 7am and 6pm or any time the Loop is open.")
      return
    }

    if (!this.isValidPhoneNumber(phoneUser)) {
      alert("Invalid cell number.")
      return;
    }

    if (
      emailUser.indexOf('@') === -1 ||
      emailUser.split('@').length !== 2 ||
      emailUser.split('@')[1].indexOf('.') === -1 ||
      emailUser.split('@')[1].split('.')[1].length < 2
    ) {
      alert("Invalid email address.");
      return;
    }

    if (!deptUser) {
      alert("Invalid user department.")
      return;
    }

    var j = {
      userEmail: emailUser,
      userPhone: phoneUser,
      userDepartment: deptUser,
      password: this.state.password || '',
      tourDate: tDate,
      tourTime: tTime,
      pickup: pickup,
      dropoff: dropoff,
      specialRequests: notes,
      passenger: nPassenger,
      status: "pending",
      driverStandby: driverStandby
    }

    console.log("JSON", j)

    fetch('https://tbc-api.click:5477/tour', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(j)
    }).then((res) => {
      if (res.status == 200) {
        window.location = "/success"
      } else {
        alert("Invalid User creditials")
      }
    })
  }

  validateHours(hoursOp, currentTime) {
    const dayOfWeek = currentTime.day();
    const hours = currentTime.hours();
    const minutes = currentTime.minutes();
    const isWeekday = dayOfWeek >= 1 && dayOfWeek <= 5;
    const isBetweenValidHours = isWeekday && (hours >= 7 && hours < 18);
    const formattedTime = currentTime.format("YYYY-MM-DD")
    let isBetweenOpenHours = false;
    if (hoursOp[formattedTime]) {
      const { isClosed, startHour, endHour } = hoursOp[formattedTime]
      if (!isClosed) {
        const hourMinutes = hours + (minutes / 60)
        isBetweenOpenHours = hourMinutes >= startHour && hourMinutes < endHour
      }
    }

    return isBetweenOpenHours || isBetweenValidHours
  }


  checkTime(tempHours) {
    let hoursOp = this.state.hoursOperation
    if (tempHours) {
      hoursOp = tempHours
    }
    const now = moment().tz('America/Los_Angeles');
    return this.validateHours(hoursOp, now)
  }

  getOperationHours = async () => {
    try {
      const response = await fetch("https://lvloop.com/operation-hours");
      if (!response.ok) {
        throw new Error('Operation hours network response was not ok');
      }
      const data = await response.json();
      if (data.status == "SUCCESS") {
        return data.opHours;
      } else {
        return [];
      }
    } catch (error) {
      console.log("Cannot fetch hours of operations", error);
      return [];
    }
  };

  render() {
    document.body.className = 'homepage-body'
    if (this.state.passwordVerified) {
      return (
        <div>
          <div id="generic-form" className={`generic-form ${!this.state.checkedbox || this.state.isValidTime ? "form-ride-details" : "form-ride-invalid"}`}>
            <img className="tbc-logo" src={tbclogo} height="200px"></img>
            <Form.Row style={{ marginBottom: "10px" }}>
              <Form.Group as={Col}>
                <div>
                  <button onClick={() => this.handleCheckbox(true)} className={`ride-container-item ${this.state.checkedbox ? "selected-button" : ""}`} type="button">
                    Pick me up Now
                  </button>
                </div>
              </Form.Group>
              <Form.Group as={Col}>
                <div>
                  <button onClick={() => this.handleCheckbox(false)} className={`ride-container-item ${!this.state.checkedbox ? "selected-button" : ""}`} type="button">
                    Book for Future
                  </button>
                </div>
              </Form.Group>
            </Form.Row>
            {(!this.state.checkedbox || this.state.isValidTime) ? <>
              <Form.Group style={{ border: "1px solid gray", padding: "10px", borderRadius: "5px" }}>
                <label style={{ textAlign: "center", fontWeight: "bold" }}>
                  Rides may only be requested from Monday to Friday between 7am and 6pm or any time the Loop is open. View <a href="https://lvloop.com/operating-hours?origin=others" class="operations-link">Hours of Operation</a>.
                </label>
              </Form.Group>
              <Form.Row style={{ marginBottom: "0px", display: this.state.checkedbox ? "none" : "" }}>
                <Form.Group as={Col} md={6} sm={12}>
                  <Form.Label>Date</Form.Label>
                  <Form.Control id="tDate" style={{ textAlign: 'center' }} type="date" onChange={this.handleDate} value={this.state.date} disabled={this.state.checkedbox} />
                </Form.Group>

                <Form.Group as={Col} md={6} sm={12}>
                  <Form.Label>Time:</Form.Label>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Control
                        as="select"
                        style={{ textAlign: 'center' }}
                        className="mr-sm-2"
                        id="aHour" custom disabled={this.state.checkedbox}>
                        <option selected={this.state.hours == 1} value="1">01</option>
                        <option selected={this.state.hours == 2} value="2">02</option>
                        <option selected={this.state.hours == 3} value="3">03</option>
                        <option selected={this.state.hours == 4} value="4">04</option>
                        <option selected={this.state.hours == 5} value="5">05</option>
                        <option selected={this.state.hours == 6} value="6">06</option>
                        <option selected={this.state.hours == 7} value="7">07</option>
                        <option selected={this.state.hours == 8} value="8">08</option>
                        <option selected={this.state.hours == 9} value="9">09</option>
                        <option selected={this.state.hours == 10} value="10">10</option>
                        <option selected={this.state.hours == 11} value="11">11</option>
                        <option selected={this.state.hours == 12} value="12">12</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Control
                        as="select"
                        style={{ textAlign: 'center' }}
                        className="mr-sm-2"
                        id="aMin" disabled={this.state.checkedbox} custom>
                        <option selected={this.state.mins == 0} value="00">00</option>
                        <option selected={this.state.mins == 10} value="10">10</option>
                        <option selected={this.state.mins == 20} value="20">20</option>
                        <option selected={this.state.mins == 30} value="30">30</option>
                        <option selected={this.state.mins == 40} value="40">40</option>
                        <option selected={this.state.mins == 50} value="50">50</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Control
                        as="select"
                        style={{ textAlign: 'center' }}
                        className="mr-sm-2"
                        id="aZone" disabled={this.state.checkedbox} custom>
                        <option selected={this.state.zone == "PM"} value="PM">PM</option>
                        <option selected={this.state.zone == "AM"} value="AM">AM</option>
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>User Email</Form.Label>
                  <Form.Control
                    className="mr-sm-2 form-control"
                    id="emailUser"
                    custom
                    placeholder='Enter email address'
                    type="email"
                  ></Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Cell No. </Form.Label>
                  <Form.Control id="phoneUser" type="tel" placeholder="Enter cell number (E.g. 2018878992) " />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Department</Form.Label>
                  <Form.Control
                    as="select"
                    className="mr-sm-2"
                    id="deptUser" custom>
                    {departments.map((dept, index) => <option key={index} value={dept}>{dept}</option>)}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>No. of Passengers</Form.Label>
                  <Form.Control
                    as="select"
                    style={{ textAlign: 'center' }}
                    className="mr-sm-2"
                    id="aPassenger" custom>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Pick-Up Location</Form.Label>
                  <Form.Control
                    as="select"
                    className="mr-sm-2"
                    id="tPickup" custom >
                    <option value="Central Station">Central Station</option>
                    <option value="South Station">South Station</option>
                    <option value="West Station">West Station</option>
                    <option value="Riviera Station">Riviera Station</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Drop-Off Location</Form.Label>
                  <Form.Control
                    as="select"
                    className="mr-sm-2"
                    id="tDropoff" custom>
                    <option value="Central Station">Central Station</option>
                    <option value="South Station">South Station</option>
                    <option value="West Station">West Station</option>
                    <option value="Riviera Station">Riviera Station</option>
                    <option value="Everywhere!">Multiple places</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formStandby">
                  <Form.Check
                    type="checkbox"
                    label="Keep Driver Standby"
                    id="standbyCheckbox"
                    style={{ marginRight: '10px' }}
                  />
                  <Form.Text className="text-muted">
                    Select this option to keep the driver on standby at the drop-off location. Please ensure the wait time does not exceed 10 minutes.
                  </Form.Text>
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <Form.Label>Special Requests (Optional)</Form.Label>
                <Form.Control id="tNotes" as="textarea" rows={2} placeholder="E.g. Tunnel color blue, Want Model X, etc" />
              </Form.Group>
              <br />

              <button className="submit-btn" onClick={this.handleSubmit}>
                Submit Request
              </button>
            </> :
              <>
                <p style={{ marginTop: "40px", padding: "20px", textAlign: "center", fontSize: "16px", fontWeight: "bold", border: "1px solid gray", borderRadius: "5px" }}>
                  Immediate pick-up requests may only be made from Monday to Friday, between 7am and 6pm or any time the loop is open (View <a href="https://lvloop.com/operating-hours?origin=others" class="operations-link">Hours of Operation</a>). Please click on the "Book for Future" button to schedule future rides.
                </p>
              </>}
            <br /><br />
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div id="generic-form" className="generic-form generic-form-password">
            <img className="tbc-logo" src={tbclogo} height="200px"></img>
            <p style={{ margin: '2%', textAlign: "center", fontSize: "20px" }}>Enter Password</p>
            <Form.Group as={Col}>
              <Form.Control id="password" style={{ textAlign: 'center' }} type="password" required />
            </Form.Group>
            <Form.Group as={Col}>
              <button className="submit-btn" onClick={this.handlePassword}>
                <span id="button-text">Access</span>
              </button>
            </Form.Group>
            <div>
            </div>
          </div>
        </div>
      )
    }
  }
}
export default HomePage
