import React from "react";

const Footer = () => {
    return (
        <div className={"footer-container"}>
            <p className="copyright-tag">
                <span>Contact Us: </span>
                <a className="contact-phone" href="tel:7252092923">
                    (725) 209-2923{" "}
                </a>{" "}
                &nbsp;|&nbsp;
                <i className="far fa-copyright"></i>
                <span>2023 Vegas Loop Operations Company. All Rights Reserved.</span>
            </p>
        </div>
    );
};

export default Footer;
