import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import logo from './assets/tbc_logo.png'

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const singleRidePassText = "$0.50";
  const dayPasstext = "$0.51";
  const blue = "#5469d4";
  const white = "white";

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const [ticketType, setTicketType] = useState('single-ride');
  const [clientSecret, setClientSecret] = useState("");
  const [ticketDisplay, setTicketDisplay] = useState("Pay " + singleRidePassText);
  const [dayPassTextColor, setDayPassTextColor] = useState(blue);
  const [singleRideTextColor, setSingleRideTextColor] = useState(white);
  const [dayPassBackgroundColor, setDayPassBackgroundColor] = useState(white);
  const [singleRideBackgroundColor, setSingleRideBackgroundColor] = useState(blue);

  document.body.className = 'stripe-body';

  useEffect(() => {
    setShouldDisplay(false);
    // Create PaymentIntent as soon as the page loads
    fetch("https://tbc-api.click/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json",
                 "Access-Control-Request-Method": "POST"},
      body: JSON.stringify({ item:ticketType }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
      .catch(console.error);
  }, [ticketDisplay]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    console.log(clientSecret);

    if(shouldDisplay) {
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
    }
    setShouldDisplay(true);
  }, [clientSecret]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://ride-lvloop.com/success",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  const handleDayPassClick = () => {
    setTicketType('day-pass');
    setDayPassBackgroundColor(blue);
    setDayPassTextColor(white);
    setSingleRideBackgroundColor(white);
    setSingleRideTextColor(blue);
    setTicketDisplay("Pay " + dayPasstext);
    
  }

  const handleSingleRideClick = () => {
    setTicketType('single-ride');
    setDayPassBackgroundColor(white);
    setDayPassTextColor(blue);
    setSingleRideBackgroundColor(blue);
    setSingleRideTextColor(white);
    setTicketDisplay("Pay " + singleRidePassText);
  }

  return (
    <div className="payment-body">
      <img src={logo} className="payment-logo"/>
      <form id="payment-form" class="stripe-form" onSubmit={handleSubmit}>
        <div className="buttonContainer">
          <button className="RideButton" onClick={handleSingleRideClick} type="button" value="single-ride" style={{
            color: singleRideTextColor,
            background: singleRideBackgroundColor,
          }}>Single Ride</button>
          <button className="RideButton" onClick={handleDayPassClick} type="button" value="day-pass" style={{
            color: dayPassTextColor,
            background: dayPassBackgroundColor,
          }}>Day Pass</button><br/>
        </div><br/>
        <PaymentElement id="payment-element" />
        <button disabled={isLoading || !stripe || !elements} id="submit" className="submitButton">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : ticketDisplay}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
}
