import './checkout.css'
import HomePage from './Components/HomePage/HomePage'
import CancelPage from './Components/CancelPage/CancelPage'
import SuccessPage from './Components/Success/SuccessPage'
import CurrentConvention from './Components/CurrentConvention/CurrentConvention'
import ConventionMap from './Components/ConventionMap/ConventionMap'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./checkout";
import Navbar from './Components/Navbar/Navbar'
import Footer from './Components/Navbar/Footer'

function App() {

  const stripePromise = loadStripe("pk_live_51KWSzUBW0fgb6jBCVXos13H0lLk956mloV6v6DqW12okRiOUfFNeXAxN9VES0J3xaZpzafA54UnYmQYHQEu7eL7400sIp9mjbh");
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("https://tbc-api.click/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json",
                 "Access-Control-Request-Method": "POST"},
      body: JSON.stringify({item:'single-ride'}),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
      .catch(console.error);
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Router>
    <Navbar />
      <div className='App'>
        <Route path='/' exact render={() => <HomePage />} />
        <Route path='/cancel/:id/:auth' exact render={(props) => <CancelPage {...props} />} />
        <Route path='/convention' exact render={() => <CurrentConvention />} /> 
        <Route path='/success' exact render={() => <SuccessPage />} />
        <Route path='/map' exact render={() => <ConventionMap />} />
        <Route path='/tickets' exact render={() => {return (clientSecret && (
          <div className='stripe-div'>
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          </div>
        ))}}/>
        
      </div>
      <Footer />
    </Router>
  );
}

export default App;
