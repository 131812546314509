import { Component } from 'react'

class ConventionMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      today: new Date('07/21/2021')
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    document.body.className = "map-body"
    return (
      <div style={{
        alignContent: 'center',
        backgroundColor: '#ffffff',
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        
      }}>
        <img src="https://tbc-api.click:8080/map" style={{ 
          width:'auto',
          height: 'auto', 
          'max-width': '100%', 
          'max-height': '100%', 
          overflow: 'hidden', 
          float: 'center', 
          display: 'flex' }} />
      </div>
    )
  }

}
export default ConventionMap
